/**
 * Renders a Heading with a Description and a Button
 * optional props are colors for heading and description
 */

import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import Button from '../../atoms/Buttons/Button/Button';
import { Heading3 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import {
  breakpoints,
  buttonSpacing,
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import Image from '../../atoms/Image/Image';
import VideoButton from '../../atoms/Buttons/VideoButton/VideoButton';

const SuiteTextBlock = ({
  button,
  className,
  description,
  descriptionColor,
  headingText,
  suiteType,
}) => {
  const headingColor = suiteType
    ? colors[suiteType].main
    : colors.darkGray.three;

  const textBlockCss = css`
    display: block;
    margin: ${uc('0 auto 20px')};

    ${Heading3} {
      max-width: ${uc('211px')};
      margin-bottom: 0;
      color: ${headingColor};
      font-weight: ${fontWeights.bold};
      font-size: ${fontSizes.thirty};
      line-height: ${lineHeights.title};

      @media (${breakpoints.tablet}) {
        font-size: ${fontSizes.twentyFive};
        letter-spacing: normal;
      }
    }

    ${Text} {
      max-width: ${uc('425px')};
      margin-bottom: 0;
      color: ${colors[descriptionColor]};
      font-weight: ${fontWeights.medium};
      font-size: ${fontSizes.twenty};
      line-height: ${lineHeights.title};
    }

    .buttons {
      display: flex;
      margin-top: ${uc('38px')};

      button,
      a {
        display: inline-block;
        align-self: initial;
        margin: 0;
        margin-right: ${buttonSpacing.marginRight};
        line-height: 1.3;

        @media (${breakpoints.mobile}) {
          display: block;
          width: 100%;
          margin-right: 0;
        }
      }
    }
  `;

  const suiteIconHeadingCss = css`
    display: flex;
    align-items: center;
    margin-bottom: ${uc('20px')};
  `;

  const imageCss = css`
    height: ${uc('122px')};
    margin-right: ${uc('20px')};
  `;

  const renderButtons = () => (
    <div className="buttons">
      {button.map(btn => {
        const buttonElement =
          btn._type === 'videoButton' ? (
            <VideoButton key={btn._key} className="video-button" {...btn}>
              {btn.buttonText}
            </VideoButton>
          ) : (
            <Button key={btn._key} {...btn}>
              {btn.text}
            </Button>
          );

        return buttonElement;
      })}
    </div>
  );

  return (
    <div css={textBlockCss} className={className}>
      <div css={suiteIconHeadingCss}>
        <Image
          css={imageCss}
          src={`/suiteAvatars/${suiteType}Avatar.svg`}
          alt={suiteType}
        />
        <Heading3>{headingText}</Heading3>
      </div>
      <Text>{description}</Text>
      {button.length > 0 ? renderButtons() : null}
    </div>
  );
};

SuiteTextBlock.propTypes = {
  button: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  headingText: PropTypes.string,
  icon: PropTypes.shape({}),
  suiteType: PropTypes.string,
};

SuiteTextBlock.defaultProps = {
  button: {},
  className: '',
  description: '',
  descriptionColor: colors.darkGray.one,
  headingText: '',
  icon: {},
  suiteType: 'learning',
};

export default SuiteTextBlock;
