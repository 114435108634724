// adds three cards as a section
// can be modified later to add things other than cards
import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  fontFamilies,
  unitConverter as uc,
} from '../../../styles/base';
import CoursesTile from '../../molecules/CoursesTile/CoursesTile';
import { Heading3 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import SuiteTextBlock from '../../molecules/SuiteTextBlock/SuiteTextBlock';
import PageWrapper from '../PageWrapper/PageWrapper';
import Section from '../../molecules/Section/Section';

const CCCTileSection = ({ coursesTiles, sectionStyles, suiteTextBlock }) => {
  const tileContainerCss = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: ${uc('20px')};

    @media (${breakpoints.desktop}) {
      justify-content: space-around;
      margin: ${uc('20px')};
    }

    @media (${breakpoints.largeTablet}) {
      align-items: center;
    }

    @media (${breakpoints.tablet}) {
      justify-content: center;
    }
  `;

  const suiteTextBlockCss = css`
    display: inline-block;
    flex: 25%;
    max-width: ${uc('300px')};
    margin: 0;
    margin-top: ${uc('40px')};
    margin-right: ${uc('20px')};
    text-align: left;

    @media (${breakpoints.largeTablet}) {
      flex: 50%;
      margin-bottom: ${uc('40px')};
    }

    @media (${breakpoints.tablet}) {
      flex: 0 0 auto;
      width: 100%;
    }
  `;

  const tileCss = css`
    flex: 25%;
    max-width: ${uc('300px')};
    margin-right: ${uc('20px')};

    @media (${breakpoints.smallDesktopMax}) {
      flex: 22%;
    }

    @media (${breakpoints.largeTablet}) {
      flex: 50%;
      margin-bottom: ${uc('40px')};
    }

    @media (${breakpoints.tablet}) {
      flex: 0 0 auto;
      width: 100%;
    }

    ${Heading3} {
      color: ${colors.primary};
      text-align: left;
    }

    ${Text} {
      color: ${colors.accent};
      font-family: ${fontFamilies.proximaSoft};
      text-align: left;
    }

    .tileBkg {
      clip-path: polygon(0 0, 100% 0, 100% 38.5%, 0 46%);
    }
  `;

  const sectionCss = css`
    position: relative;
    text-align: center;
  `;

  const renderedCoursesTiles = coursesTiles.map(tile => (
    <CoursesTile css={tileCss} key={`courses-tile-${tile._rev}`} {...tile} />
  ));

  return (
    <Section sectionStyles={sectionStyles} css={sectionCss}>
      <PageWrapper>
        <div css={tileContainerCss}>
          <SuiteTextBlock css={suiteTextBlockCss} {...suiteTextBlock} />
          {renderedCoursesTiles}
        </div>
      </PageWrapper>
    </Section>
  );
};

CCCTileSection.propTypes = {
  suiteTextBlock: PropTypes.shape({}),
  coursesTiles: PropTypes.arrayOf(PropTypes.shape({})),
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

CCCTileSection.defaultProps = {
  suiteTextBlock: {},
  coursesTiles: [],
  sectionStyles: {},
};

export default CCCTileSection;
